import React from "react";
import { Container } from "react-bootstrap";

function AnchorTag({ showingBG, onClickBackground, onClickExperiences }) {
  return (
    <Container
      className="debugcss1 p-4 d-none d-md-block"
      style={{ textAlign: "right", height: "10rem" }}
    >
      <p onClick={onClickBackground} className={showingBG ? "anchor anchor-on" : "anchor"}>
        {" "}
        --- Background
      </p>
      <p onClick={onClickExperiences} className={showingBG ? "anchor" : "anchor anchor-on"}> --- Experiences</p>
    </Container>
  );
}

export default AnchorTag;
