import React from 'react'
import Container from 'react-bootstrap/Container'
import Background from './Background'
import Experiences from './Experiences'
function CV({ isMD }) {
  return (
    <Container id='cv' className='debugcss3' style={{maxHeight: isMD ? "90vh" :"", overflow:"hidden", padding:"2rem"}} >
        <Background></Background>
        <Container className='spacer'></Container>
        <Experiences></Experiences>
        <br></br>
    </Container>
  )
}

export default CV