import "./App.css";
import React, { useEffect, useState, useTransition } from "react";
import Container from "react-bootstrap/Container";
import NameCard from "./components/NameCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CV from "./components/CV";
import $ from "jquery";
import AnchorTag from "components/AnchorTag";
import Contacts from "components/Contacts";
import MyCursor from "components/MyCursor";


function App() {
  const mdScreen = 768;
  const [showingBG, setShowingBG] = useState(true);
  const [isMD, setIsMD] = useState(true);

  const trackWheel = (event) => {
    // console.log(event.deltaX);
    let cv = $("#cv");
    // console.log(experiences.scrollTop());
    cv.scrollTop(cv.scrollTop() + event.deltaY * 0.5);
    event.preventDefault();

    let summary = $("#background");
    let rect = summary[0].getBoundingClientRect();
    let Y = rect.y + (rect.bottom + rect.height) / 2;

    // console.log(Y)

    if (Y > 0) {
      setShowingBG(true);
    } else {
      setShowingBG(false);
    }
  };

  const goToBG = () => {
    setShowingBG(true);
    document.getElementById("background").scrollIntoView();
  };

  const goToEXP = () => {
    setShowingBG(false);
    document.getElementById("experiences").scrollIntoView();
  };

  useEffect(() => {
    window.addEventListener("wheel", trackWheel, { passive: false });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      if (window.innerWidth > mdScreen) {
        setIsMD(true);
        window.addEventListener("wheel", trackWheel, { passive: false });
      } else {
        setIsMD(false);
        window.removeEventListener("wheel", trackWheel);
      }
    });

    if (window.innerWidth > mdScreen) {
      setIsMD(true);
      window.addEventListener("wheel", trackWheel, { passive: false });
    } else {
      setIsMD(false);
      window.removeEventListener("wheel", trackWheel);
    }
  }, []);

  return (
    <div className="App">
      <Row className="debugcss2 mx-1">
        <Col md={1} />
        <Col md={4}>
          <div className="d-none d-md-block my-5"></div>
          <NameCard></NameCard>
          <Contacts></Contacts>
          <hr className="d-md-none"></hr>
          <AnchorTag
            onClickBackground={goToBG}
            onClickExperiences={goToEXP}
            showingBG={showingBG}
          ></AnchorTag>
        </Col>
        <Col md={6}>
          <CV isMD={isMD}></CV>
        </Col>
        <Col md={1} />
      </Row>
    </div>
  );
}

export default App;
