import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

function NameCard() {
  return (
    <Container className="my-1 debugcss1">
      <Card
        className="name-card border-0"
        style={{ maxWidth:"30rem", textAlign: "start" }}
      >
        <Card.Body>
          <Card.Title className="text-primary fs-1" >THOMAS LUO</Card.Title>
          <Card.Subtitle className="mb-1 text-info">
          Software Engineer / SDET 
          </Card.Subtitle>
          <br></br>
          <Card.Text >
            Professional working experiences in software developing teams. Spent 3+ years in the industry as a QA. Now looking for new opportunities.
          </Card.Text>
          {/* <Card.Link href="#">Card Link</Card.Link>
          <Card.Link href="#">Another Link</Card.Link> */}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default NameCard;
