import React from "react";
import { Button, Container } from "react-bootstrap";

function Contacts() {
  return (
    <Container className="debugcss1 my-3 px-4" style={{ textAlign: "left" }}>
      <Button
        href="https://www.linkedin.com/in/luozhengiw95/"
        className="border-0 btn-light"
      >
        <i className="bi bi-linkedin h4"></i>
      </Button>
      <Button
        onClick={() => {
          window.location.href = "mailto:luozhengiw@gmail.com";
        }}
        className="border-0 btn-light"
      >
        <i className="bi bi-envelope-at-fill h4"></i>
      </Button>
    </Container>
  );
}

export default Contacts;
