import React from "react";
import { Badge, Col, Row } from "react-bootstrap";

const Detail = ({ time, description, pills, place, role, trivia, link }) => {
  return (
    <div
      className="mb-5 detail-card"
      onClick={() => {
        window.location.href = link;
      }}
    >
      <Row>
        <Col className="debugcss1" md={3}>
          <p>{time}</p>
        </Col>
        <Col md={9} className="debugcss2 " style={{ textAlign: "start" }}>
          <h5 className="text-primary fs-4">{place}</h5>
          <p className="highlight text-info">{role}</p>
          <p>{description}</p>
          <p style={{ fontSize: "0.9rem,", fontStyle: "italic" }}>{trivia}</p>
          {pills.map((v, i) => {
            return (
              <Badge key={i} className="mx-1 bg-secondary">
                {v}
              </Badge>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

function Experiences() {
  return (
    <div className="" id="experiences">
      <Detail
        trivia={""}
        link={
          "https://www.oracle.com/sg/cloud/"
        }
        place="Oracle Corporation Singapore Pte Ltd"
        role="Java Developer Intern"
        time="2024 Mar - Present"
        description="Working with a brilliant team of engineers to provide the Java Management Services to Oracle Cloud Infrastructure customers. A great chance for me to deep dive into the intricacies of Java and to get hands-on experiences with JVM monitoring."
        pills={[
          "Java 8",
          "JVM",
          "Cloud",
          "K8S"
        ]}
      ></Detail>
      <Detail
        trivia={"For some topics, we are given semester-length materials and asked to learn them in weeks."}
        link={
          "https://www.iss.nus.edu.sg/graduate-programmes/programme/detail/graduate-diploma-in-systems-analysis"
        }
        place="NUS Graduate Diploma in System Analysis"
        role="Student"
        time="2023 Aug - 2024 Mar"
        description="Impacted by a recent layoff, I decided to go back to school for a change. I have always wanted to learn software engineering skills in a formal learning environment. The course is undoubtedly super compact and the syllabus ambitiously aims to teach us not only book knowledge but also industrial software engineering practices. I would have definitely struggled a lot if not for the work experiences I had before joining.
    "
        pills={[
          "System Design",
          "Web Development",
          "Java",
          "React",
          "Spring Boot",
          ".NET Core",
          "C#",
          "Python",
          "ML Fundamentals",
        ]}
      ></Detail>
      <Detail
        link={"https://indeed.com"}
        place="Indeed"
        role="Automation QA Engineer II"
        time="2022 Aug - 2023 Mar"
        description="I provided QA support in a dev team of 10+ engineers. The work was challenging yet extremely rewarding. It was the shortest amount of time I have been with any company, but during my 8 months as an Indeedian I learnt more than ever - about how to work and how to live."
        trivia="My team consisted of mainly members from Japan, and we worked with an 1-hour timezone difference."
        pills={[
          "Unit Testing",
          "Integration Testing",
          "Test Automation",
          "Cypress",
          "Selenium",
          "Playwright",
          "AWS",
          "CI/CD",
        ]}
      ></Detail>
      <Detail
        link={"https://shopee.com"}
        place="Shopee"
        role="Automation QA Engineer"
        time="2019 Dec - 2022 May"
        description="I was in the iOS Automation QA Team. We put a lot of efforts into maintaining the test-cases built with XCUITests. Testing could be fun, sometimes."
        trivia={"I still use Shopee occasionally :D"}
        pills={["iOS", "XCTest", "XCUITests", "Obj-C"]}
      ></Detail>
      <Detail
        link={"https://sg.micron.com/"}
        place="Micron"
        role="System Engineer"
        time="2018 Aug - 2019 Dec"
        description="My first job as a salaryman. I worked in the team supporting the Automated Material Handling System (little robots tirelessly traversing around  the semi-con plant to transport silicon wafers). The factory was at the far northern area of Singapore, the food was bad... But I met some of my best colleagues/friends/climbing-buddies there. We still hang out weekly now."
        trivia={"We could see Johor Bahru from our office window."}
        pills={["Perl", "Linux", "Debugging", "AMHS"]}
      ></Detail>
    </div>
  );
}

export default Experiences;
